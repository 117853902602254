import React, {useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(styles);

const WorkSection = (props) => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const sendEmail = async () => {
    try {
        const response = await fetch(
            'https://r2fmgkq7s6.execute-api.us-west-1.amazonaws.com/prod/contact-us',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    key1: name,
                    key2: email,
                    key3: message
                })
            }
          );
        const data = await response.json();
        console.log(data);
        // redirect
        props.history.push('/profile-page');
        props.history.push('/');
    } catch (e) {
        console.log(`error: ${e.message}`);
        alert(`Uh Oh! There was an issue sending your message. Please try again later.\n${e.message}`);
    }
  };

  return (
    <div className={classes.section}
    >
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact Us</h2>
          <h4 className={classes.description}>
            Contact us today with any questions or if you would like to schedule an appointment. One of our dedicated public adjusters will get back to you shortly.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                      onChange: (e) => setName(e.target.value)
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                      onChange: (e) => setEmail(e.target.value)
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: (e) => setMessage(e.target.value)
                }}
              />
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                  <Button onClick={() => sendEmail()} color="primary">Send Message</Button>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withRouter(WorkSection)
