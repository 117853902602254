import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Why Choose Us?</h2>
          <h5 className={classes.description}>
            By enlisting our assistance, we will relieve you of the emotional burden of having to fight an insurance company for months upon months.
            We  assist in investigating and evaluating your loss. We review your coverage and exclusions so you receive fair compensation.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Dependable"
              description="Recovering from a loss can be an arduous and draining process. Our promise is that you don't have to go through it alone. A public adjuster works for the insured, NOT the insurance company."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Experienced"
              description="When you file a claim with an insurance company, they will send an adjuster to assess your damage. This adjuster works for the insurance company, and will have their best interest in mind. They have a financial responsibility to the insurer, not to you."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Individual Focused"
              description="Recovering from a loss can be draining, our promise is that you don't have to go through it alone. We promise to represent you fairly to your insurance company so that you recieve as much money as you should recieve."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
