import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import car1 from "assets/img/car1.jpg";
import fire1 from "assets/img/fire1.jpg";
import fire2 from "assets/img/fire2.jpg";
import fire3 from "assets/img/firedamage.jpg";
import flood1 from "assets/img/flood1.jpg";
import hurricane1 from "assets/img/hurricane1.jpg";
import tree1 from "assets/img/treedamage.jpg";
import water1 from "assets/img/water1.jpg";
import water2 from "assets/img/waterdamage.jpg";
import businessInterruption1 from "assets/img/businessinterruption1.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>What Disasters Do We Handle?</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={fire1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Fire
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Fires cause additional damage beyond what burns. Smoke, residue, and even water damage can occur. This can lead to a complex claims process that could interrupt you or your business’s recovery.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={businessInterruption1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Commercial Property
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  If disaster suspends your business operations, the coverage for your business income loss can become difficult to determine without an expert advocate on your side.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={hurricane1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Hurricane
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Florida is no stranger to the many types of damage brought upon by a bad hurricane. This can complicate the insurance claims process if not documented and managed with expertise.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={flood1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Flooding
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Water damage isn’t the only hazard a flood carries to your property.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={tree1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Lightning
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                    Lightning strikes and the next thing you know, unprecedented damage occurs.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={car1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Theft & Vandalism
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                    Property damage caused by others or worse can occur at any time and be difficult to claim.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
